// window._ = require('lodash');
window.$ = window.jQuery = require('jquery');
require('Modernizr');
require('bootstrap');
require('owl.carousel');

$(function () { // Document Ready

    if (
        "IntersectionObserver" in window &&
        "IntersectionObserverEntry" in window &&
        "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
        let observer = new IntersectionObserver(entries => {
            if (entries[0].boundingClientRect.y < 0) {
                document.body.classList.add("header-not-at-top");
            } else {
                document.body.classList.remove("header-not-at-top");
            }
        });
        observer.observe(document.querySelector("#top-of-site-pixel-anchor"));
    }

    // mobile menu tweaks
    $('.hamburger').on('click', function () {
        if ($('.header__menu--mobile').is(":visible")) {
            $('.header__menu--mobile').slideUp('fast');
            $('body').removeClass('mobile-menu-open');
        } else {
            $('.header__menu--mobile').slideDown('fast');
            $('body').addClass('mobile-menu-open');
        }
    });

    $('.custom-dropdown').on('click', function () {
        $(this).closest('.custom-dropdown-wrapper').find('.custom-dropdown-menu').toggle();
        $(this).find('.rotate').toggleClass('down')
    })
});